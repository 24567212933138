import {
  AssignmentOutlined,
  BurstMode,
  Event,
  FolderSharedOutlined,
  HomeOutlined,
  InsertDriveFileOutlined,
  Memory,
  PersonOutlineOutlined,
  SupervisedUserCircleOutlined,
} from '@material-ui/icons';

export const MenuOptionObjects = {
  hospital: {
    main: {
      dashboard: {
        label: 'dashboard',
        link: '/dashboard',
        matcher: 'dashboard',
        icon: {
          name: 'dashboard',
        },
        icon2: HomeOutlined,
      },
      cases: {
        label: 'cases',
        link: '/cases',
        matcher: 'case',
        icon: {
          name: 'cases',
        },
        icon2: FolderSharedOutlined,
      },
      patients: {
        label: 'patients',
        link: '/patients',
        matcher: 'patient',
        icon: {
          name: 'patient',
        },
        icon2: PersonOutlineOutlined,
      },
      groups: {
        label: 'groups',
        link: '/groups',
        matcher: 'groups',
        icon: {
          name: 'group',
        },
        icon2: SupervisedUserCircleOutlined,
      },
      protocols: {
        label: 'protocols',
        link: '/protocols',
        matcher: 'protocols',
        icon: {
          name: 'file-text',
        },
        icon2: AssignmentOutlined,
      },
      virtualVisits: {
        label: 'virtual.visits',
        link: '/virtual-visits',
        matcher: 'virtual-visit',
        icon: {
          name: 'calendar',
        },
        icon2: Event,
      },
      advancedToolStats: {
        label: 'ai.stats',
        link: '/advanced-tools/stats',
        matcher: 'advanced-tool',
        icon: {
          name: 'memory',
        },
        icon2: Memory,
        can: 'delete',
        available: ((elem) => elem.availableAdvancedTools && elem.availableAdvancedTools.length),
      },
      studies: {
        label: 'studies',
        link: '/studies',
        matcher: 'study',
        icon: {
          name: 'file-text2',
        },
        icon2: BurstMode,
      },
      files: {
        label: 'files',
        link: '/files',
        matcher: 'file',
        icon: {
          name: 'file-text2',
        },
        icon2: InsertDriveFileOutlined,
      },
    },
    settings: {
      general: {
        label: 'hospital.settings.general',
        link: '/hospital/settings/edit',
        matcher: 'settings/edit',
      },
      team: {
        label: 'hospital.settings.team',
        link: '/hospital/settings/team',
        matcher: 'settings/team',
      },
      group: {
        label: 'hospital.settings.group',
        link: '/hospital/settings/group',
        matcher: 'settings/group',
      },
      patients: {
        label: 'hospital.settings.patients',
        link: '/hospital/settings/patients',
        matcher: 'settings/patients',
      },
      back: {
        label: 'back.to.dashboard',
        link: '/dashboard',
        matcher: 'dashboard',
      },
    },
  },
  provider: {
    advancedTools: {
      label: 'advanced.tools',
      link: '/advanced-tools',
      matcher: 'advanced-tools',
      icon: {
        name: 'wrench',
      },
    },
  },
  user: {
    settings: {
      profile: {
        label: 'user.settings.profile',
        link: '/user/settings/edit',
        matcher: 'settings/edit',
      },
      plan: {
        label: 'user.settings.plan',
        link: '/user/settings/plan',
        matcher: 'settings/plan',
      },
      back: {
        label: 'back.to.hospital',
        link: '/dashboard',
        matcher: 'dashboard',
      },
    },
  },
};
