import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { fade } from '@material-ui/core/styles';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip, withStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Can from '../../casl/Can';
import { GET_HOSPITALS, GET_PROVIDERS, GET_USER_PATIENTS } from '../../graphql/queries';
import { UPDATE_USER_PATIENT_UUID } from '../../graphql/mutations';
import { isEmpty } from '../../utils/ObjectUtils';
import { getSessionStorageJson } from '../../utils/ReadStorageUtils';
import { roleOptions } from '../Hospital/TeamManagement/TeamUtils';
import { LoadingAutocomplete } from '../Form/Autocomplete/Autocomplete';
import { SwitcherPatientDialog } from './SwitcherPatientDialog';

const ListItemTitle = withStyles((theme) => ({
  root: {
    '& .MuiTypography-body1': {
      fontFamily: 'Muli',
      fontSize: '1.6rem',
      fontWeight: 800,
    },
    '& .MuiTypography-body2': {
      fontFamily: 'Muli',
      fontSize: '1.4rem',
      fontWeight: 300,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))(ListItem);

const ListItemHospital = withStyles((theme) => ({
  root: {
    '& .MuiTypography-body1': {
      fontFamily: 'Muli',
    },
    '& .MuiTypography-body2': {
      fontFamily: 'Muli',
    },
    '&$selected': {
      '& .MuiTypography-body1': {
        fontWeight: 800,
      },
      '& .MuiListItemIcon-root': {
        color: fade(theme.palette.primary.main, 0.84),
      },
    },
    '& .MuiIconButton-root:hover': {
      color: fade(theme.palette.primary.main, 0.84),
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))(ListItem);

const ListItemAdd = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      // color: theme.palette.primary.main,
      minWidth: 40,
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Muli',
      fontSize: '1.rem',
      fontWeight: 600,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  selected: {},
}))(ListItem);

export default ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fixSidebar, user, visibleWorkspaces } = useSelector((state) => state.userInterface);
  const { data: queryHospitalData } = useQuery(GET_HOSPITALS);
  const { data: queryProviderData } = useQuery(GET_PROVIDERS);
  const currentHospital = useSelector((state) => state.hospital);
  const currentProvider = useSelector((state) => state.provider);
  const [hospitalUuid, setHospitalUuid] = useState(currentHospital.uuid);
  const toNavigate = (url) => {
    dispatch({ type: 'SET_VISIBILITY_FILTER', filter: { visibleSidebar: false, visibleWorkspaces: false } });

    const timer = (fixSidebar) ? 0 : 200;
    setTimeout(() => navigate(url), timer);
  };

  const onClickSwitcher = () => dispatch(
    {
      type: 'SET_VISIBILITY_FILTER',
      filter: { visibleWorkspaces: !visibleWorkspaces },
    },
  );

  const switchHospital = (e, hospital) => {
    setHospitalUuid(hospital.uuid);
    dispatch({ type: 'CHANGE_HOSPITAL', current: hospital });
    onClickSwitcher();
    navigate('/dashboard');
  };

  useEffect(() => {
    localStorage.setItem('hospitalUuid', hospitalUuid);
  }, [hospitalUuid]);

  const switchProvider = (e, provider) => {
    dispatch({ type: 'CHANGE_PROVIDER', current: provider });
    navigate('/advanced-tools');
  };

  const goToSettings = (e) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    toNavigate('/hospital/settings/edit/');
  };

  const hospitalData = !isEmpty(queryHospitalData) ? queryHospitalData : getSessionStorageJson('hospitalData');
  const providerData = !isEmpty(queryProviderData) ? queryProviderData : getSessionStorageJson('providerData');

  const hospitals = hospitalData && hospitalData.hospitals && hospitalData.hospitals.edges.map((edge) => {
    if (!user) return edge;

    const hospital = edge;

    if (edge.node.owner === user.uuid) {
      hospital.node.userRole = 'MANAGER';
      return hospital;
    }

    const member = edge.node.teamMembers.find((tm) => tm.user.uuid === user.uuid);
    hospital.node.userRole = (member && member.role) || 'UNKNOWN';
    return hospital;
  });

  const getUserRoleIndex = ({ userRole }, defaultValue = 'role.standard') => {
    if (!userRole) return defaultValue;

    const role = roleOptions.find(({ value }) => value === userRole);
    if (!role) return defaultValue;

    return role.label ? role.label : defaultValue;
  };

  const createHospitalAndProviderList = () => (
    <>
      <List component="div" disablePadding>
        {hospitals && hospitals.map(({ node }) => (
          <ListItemHospital
            button
            key={node.uuid}
            onClick={(e) => switchHospital(e, node)}
            selected={node.uuid === currentHospital.uuid && type !== 'provider'}
          >
            <ListItemText
              primary={node.name}
              secondary={t(getUserRoleIndex(node, 'role.standard'))}
            />
            {node.uuid === currentHospital.uuid && type !== 'provider' && (
              <Can I="manage" this={node}>
                {() => (
                  <IconButton disableRipple onClick={(e) => goToSettings(e)}><SettingsIcon /></IconButton>
                )}
              </Can>
            )}
          </ListItemHospital>
        ))}
        {providerData.providers.edges.map(({ node }) => (
          <ListItemHospital
            button
            key={node.uuid}
            onClick={(e) => switchProvider(e, node)}
            selected={node.uuid === currentHospital.uuid && type !== 'provider'}
          >
            <ListItemText primary={node.name} secondary={t('provider')} />
          </ListItemHospital>
        ))}
        <ListItemAdd
          button
          onClick={() => toNavigate('/hospital/new')}
        >
          <ListItemText primary={t('create.hospital')} />
          <ListItemIcon><AddIcon fontSize="large" /></ListItemIcon>
        </ListItemAdd>
      </List>
    </>
  );

  const hospital = hospitals.find(({ node }) => node.uuid === currentHospital.uuid);
  const userRole = hospital && hospital.node && hospital.node.userRole;

  return (
    <>
      <List>
        <ListItemTitle button onClick={onClickSwitcher}>
          <ListItemText
            primary={type === 'provider' ? currentProvider && currentProvider.name : currentHospital && currentHospital.name}
            secondary={t(getUserRoleIndex({ userRole })).toUpperCase()}
          />
          {visibleWorkspaces
            ? <ExpandLess />
            : (
              <Tooltip title={t('change.workspace')}>
                <ExpandMore />
              </Tooltip>
            )}
        </ListItemTitle>
        <Collapse in={visibleWorkspaces} timeout="auto" unmountOnExit>
          { isEmpty(hospitalData) || isEmpty(providerData) ? <LoadingAutocomplete /> : createHospitalAndProviderList() }
        </Collapse>
      </List>
    </>
  );
};

export const PatientSwitcher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userInterface.user);
  const { data: patientsData } = useQuery(GET_USER_PATIENTS);
  const { visibleWorkspaces } = useSelector((state) => state.userInterface);

  const [updateModal, setUpdateModal] = useState(false);
  const [serverError, setServerError] = useState(false);

  const currentHospital = useSelector((state) => state.hospital);
  const [hospitalUuid, setHospitalUuid] = useState(currentHospital.uuid);

  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  const [updateUserPatientUuid, { error: userPatientUpdateError, loading: loadingUpdate }] = useMutation(UPDATE_USER_PATIENT_UUID, {
    onCompleted(data) {
      if (data && data.updateUserPatientUuid) {
        user.patientUuid = data.updateUserPatientUuid.patientUuid;
      }
      closeUpdateModal();
      navigate('/dashboard/');
    },
    onError() {
      setServerError(userPatientUpdateError);
    },
  });

  const userPatients = patientsData && patientsData.userPatients;

  const onClickSwitcher = () => {
    if (!userPatients) return;
    if (userPatients.length < 2) return;
    dispatch(
      {
        type: 'SET_VISIBILITY_FILTER',
        filter: { visibleWorkspaces: !visibleWorkspaces },
      },
    );
  };

  const switchPatientHospital = async (e, node) => {
    if (node.uuid === user.patientUuid) return;
    setUpdateModal(true);
    setHospitalUuid(node.hospital.uuid);
    dispatch({ type: 'CHANGE_HOSPITAL', current: node.hospital });
    await updateUserPatientUuid({ variables: { patientUuid: node.uuid } });
    navigate('/dashboard');
  };

  useEffect(() => {
    localStorage.setItem('hospitalUuid', hospitalUuid);
  }, [hospitalUuid]);

  const createHospitalList = () => (
    <>
      <List component="div" disablePadding>
        {userPatients && userPatients.map((node) => (
          <ListItemHospital
            button
            key={node.uuid}
            onClick={(e) => switchPatientHospital(e, node)}
            selected={node.uuid === user.patientUuid}
          >
            <ListItemText
              primary={node.hospital.name}
            />
          </ListItemHospital>
        ))}
      </List>
    </>
  );

  return (
    <>
      <SwitcherPatientDialog
        open={updateModal}
        onClose={closeUpdateModal}
        loadingUpdate={loadingUpdate}
        serverError={serverError}
      />
      <List>
        <ListItemTitle button onClick={onClickSwitcher}>
          <ListItemText
            primary={currentHospital.name}
          />
          {userPatients && userPatients.length > 1 && (
            visibleWorkspaces
              ? <ExpandLess />
              : (
                <Tooltip title={t('change.hospital.environment')}>
                  <ExpandMore />
                </Tooltip>
              )
          )}
        </ListItemTitle>
        <Collapse in={visibleWorkspaces} timeout="auto" unmountOnExit>
          { isEmpty(patientsData) ? <LoadingAutocomplete /> : createHospitalList() }
        </Collapse>
      </List>
    </>
  );
};
