import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  text: {
    maxWidth: 250,
    fontSize: '13px',
    lineHeight: 1.4,
    textAlign: 'left',
    margin: '0 5px',
    cursor: 'default',
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}));

// export const LinkTextButtonUI = ({ color, size, variant, children }) => {
export const LinkTextUI = ({ color, size, variant, noWrap, children }) => {
  const classes = useStyles();

  return (
    <Typography
      className={`${classes.text} ${classes.link}`}
      variant={variant}
      color={color}
      size={size}
      noWrap={noWrap}
    >
      {children}
    </Typography>
  );
};

export default LinkTextUI;
