import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
    '&.selected .MuiSvgIcon-root': {
      fill: theme.palette.primary.dark,
    },
    '&:hover .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },

}));

export const IconButtonUI = ({
  children,
  title = '',
  handleClick,
  badgeContent,
  disabled,
  selected,
  Icon = ErrorIcon,
  size,
  placement = 'bottom',
}) => {
  const classes = useStyles();
  const [tooltipActive, setTooltipActive] = useState(false);

  const iconCustomStyle = { fontSize: size || 'inherit' };
  const iconButtonClass = `${classes.button}${selected ? ' selected' : ''}`;

  const handleShowTooltip = () => { if (!disabled) setTooltipActive(true); };
  const handleHideTooltip = () => setTooltipActive(false);

  return (
    <Tooltip
      arrow
      title={title}
      TransitionComponent={Zoom}
      placement={placement}
      open={tooltipActive && !disabled}
      onOpen={handleShowTooltip}
      onClose={handleHideTooltip}
    >
      <span className="icon-wrapper">
        <IconButton className={iconButtonClass} disabled={disabled} onClick={handleClick}>
          <Badge badgeContent={badgeContent} color={disabled ? 'secondary' : 'primary'}>
            <Icon fontSize="default" style={iconCustomStyle} />
          </Badge>
        </IconButton>
        { children }
      </span>
    </Tooltip>
  );
};

export default IconButtonUI;
