import React from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '@material-ui/icons';
import { Box, InputBase } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

const useClasses = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    display: 'inline-flex',
    position: 'relative',
    border: `1px solid ${fade(theme.palette.primary.light, 0.1)}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.02),
    '&:hover': {
      border: `1px solid ${fade(theme.palette.primary.light, 0.05)}`,
      backgroundColor: fade(theme.palette.common.black, 0.04),
    },
    '&:focus-within': {
      border: `1px solid ${fade(theme.palette.primary.light, 0.05)}`,
      backgroundColor: fade(theme.palette.common.black, 0.04),
    },

    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(1, 1.5),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: theme.spacing(1.5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '8ch',
      '&:focus': {
        width: '35ch',
      },
    },
  },
}));

const useInputClasses = makeStyles((theme) => ({
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: theme.spacing(1.5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '8ch',
      '&:focus': {
        width: '35ch',
      },
    },
  },
}));

export default ({ name, value, styles, onChange, placeholder }) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const inputStyles = useInputClasses();
  const input = styles || inputStyles;
  const inputPlaceholder = placeholder || t('search');

  return (
    <Box className={classes.search}>
      <InputBase
        autoComplete="off"
        name={name}
        placeholder={inputPlaceholder}
        value={value}
        classes={{
          root: input.inputRoot,
          input: input.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={onChange}
      />
      <div className={classes.searchIcon}>
        <Search />
      </div>
    </Box>
  );
};
