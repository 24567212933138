import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from '@material-ui/core/Drawer';
import { Divider } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@material-ui/icons/ToggleOnOutlined';
import { useMinWidth } from '../../hooks/useResize';
import { sizes } from '../../styles/variables';
import { LogoWrapper } from '../Header/styled/LogoWrapper';
import Logo from '../Header/Logo';
import WorkspaceName from './WorkspaceName';
import HospitalSwitcher from './Switcher';
import Menu from './Menu';

import {
  hospitalMenuOptions,
  providerMenuOptions,
  hospitalSettingsMenuOptions,
  userSettingsMenuOptions,
} from './MenuOptions';

const drawerWidth = 285;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  separator: {
    height: 10,
    width: '100%',
  },
  icon: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 999,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      zIndex: 10,
      boxShadow: '0 0 .75em #00000057',
      scrollbarWidth: 'none',
    },
    '& .MuiDrawer-paper::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  noDrawer: {
    display: 'none',
  },
  userData: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2.75rem',
    color: 'white',
    backgroundColor: theme.palette.primary.light,
  },
}));

export default ({ type }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { visibleSidebar, fixSidebar } = useSelector((state) => state.userInterface);
  const classes = useStyles();
  const isMinWidth = useMinWidth(sizes.mdMin);

  if (type === 'new') return null;

  const handleDrawerToggle = () => {
    dispatch({ type: 'SET_VISIBILITY_FILTER', filter: { visibleSidebar: false, visibleWorkspaces: false } });
  };

  const handleSwitch = () => {
    dispatch({ type: 'SET_VISIBILITY_FILTER', filter: { visibleSidebar: false, visibleWorkspaces: false, fixSidebar: !fixSidebar } });
  };

  function getMenuOptions() {
    let menuOptions;
    switch (type) {
      case 'provider':
        menuOptions = providerMenuOptions;
        break;
      case 'settings':
        menuOptions = hospitalSettingsMenuOptions;
        break;
      case 'userSettings':
        menuOptions = userSettingsMenuOptions;
        break;
      default:
        menuOptions = hospitalMenuOptions;
        break;
    }
    return menuOptions;
  }

  const drawer = (
    <>
      {!isMinWidth && (
        <IconButton className={classes.icon} onClick={handleSwitch}>
          {fixSidebar
            ? <ToggleOnOutlinedIcon size="small" color="primary" />
            : <ToggleOffOutlinedIcon size="small" />}
        </IconButton>
      )}
      <div className={classes.separator} />
      {type === 'userSettings' ? (
        <div className={classes.userData}>
          <span>{t('user.data')}</span>
        </div>
      ) : (
        <>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          {type === 'settings'
            ? <WorkspaceName type={type} py-6 px-9 pb-0 />
            : <HospitalSwitcher type={type} />}
        </>
      )}
      <Divider color="primary" />
      <Menu fixSidebar={fixSidebar} options={getMenuOptions()} />
    </>
  );

  return (
    <nav className={(fixSidebar && !isMinWidth) ? classes.drawer : classes.noDrawer}>
      <Drawer
        variant={(fixSidebar && !isMinWidth) ? 'permanent' : 'temporary'}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={visibleSidebar}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};
