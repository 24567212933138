import React from 'react';
import { useSelector } from 'react-redux';
import App from './App';

import Sidebar from '../components/Sidebar/Sidebar';
import PatientSidebar from './Sidebar/PatientSidebar';
import { ContentWrapper } from './Common/styled/ContentWrapper';
import Header from './Header/Header';
import { MainContainer } from './Common/styled/MainContainer';
import { LicenseWarning } from './Common/LicenseWarning';
import { getUserInterface } from '../utils/patientUserHospitalUtils';

export default ({ children, type }) => {
  const { fixSidebar, licenseWarning } = useSelector((state) => state.userInterface);
  const userInterface = getUserInterface();

  return (
    <App>
      {userInterface === 'patient' ? <PatientSidebar type={type} /> : <Sidebar type={type} />}
      <ContentWrapper fixSidebar={fixSidebar}>
        <Header type={type} fixSidebar={fixSidebar} />
        <MainContainer>
          {licenseWarning && <LicenseWarning />}
          {children}
        </MainContainer>
      </ContentWrapper>
    </App>
  );
};
